import React from 'react';
import "./style.css"

import { Space, Table, Tag, Row, Divider } from 'antd';
const { Column, ColumnGroup } = Table;

const ProgramGovernanceTable = ({ data }) => {
  const siteName = data.program_governance_w6
  console.log(siteName[0].planed, "siteName[0].planed");
  const tableData = siteName?.map?.((siteNames, index) => ({
    key: index.toString(),
    title: siteNames.title,
    planned_PMO: siteNames.planned_PMO,
    status_PMO: siteNames.status_PMO,
    planned_staff: siteNames.planned_staff,
    status_staff: siteNames.status_staff,
    planned_representative_cluster: siteNames.planned_representative_cluster,
    status_representative_cluster: siteNames.status_representative_cluster,
    planned_representative_heathlco: siteNames.planned_representative_heathlco,
    status_representative_heathlco: siteNames.status_representative_heathlco,


  }));
  return (
    <div>
      <Table bordered dataSource={tableData} pagination={false} >
        <ColumnGroup className='main-head' title="Program Governance">
          <Column
    className="main-head"
    title={<span className="title-style">Workstream</span>}
    dataIndex="title"
    key="title"
    render={(text, record) => (
      <span className="value-style">{text}</span>
    )}
  />
          <ColumnGroup className='main-head-man-op' title="PMO/Huddles">
            <Column className='mini-head' title="Planned" dataIndex="planned_PMO" key="planned_PMO"/>
            <Column className='mini-head' title="Status" dataIndex="status_PMO" key="status_PMO"/>
          </ColumnGroup>
          <ColumnGroup className='main-head-man-op' title="Site Staff">
            <Column className='mini-head' title="Planned" dataIndex="planned_staff" key="planned_staff"/>
            <Column className='mini-head' title="Status" dataIndex="status_staff" key="status_staff"/>
          </ColumnGroup>
          <ColumnGroup className='main-head-man-op' title="Cluster Representative">
            <Column className='mini-head' title="Planned" dataIndex="planned_representative_cluster" key="planned_representative_cluster"/>
            <Column className='mini-head' title="Status" dataIndex="status_representative_cluster" key="status_representative_cluster"/>
          </ColumnGroup>
          <ColumnGroup className='main-head-man-op' title="Healthco Representative">
            <Column className='mini-head' title="Planned" dataIndex="planned_representative_heathlco" key="planned_representative_heathlco"/>
            <Column className='mini-head' title="Status" dataIndex="status_representative_heathlco" key="status_representative_heathlco"/>
          </ColumnGroup>
        </ColumnGroup>
      </Table>
    </div>
  );
};

export default ProgramGovernanceTable;