import React from 'react';
import "./style.css"

import { Space, Table, Tag, Row, Divider } from 'antd';
const { Column, ColumnGroup } = Table;

const ConstructionAsharValleyTable = ({ data }) => {
  const siteName = data.construction_ashar_w5
  const constructionUpload = data.construction_uplaod_picture
  console.log(siteName[0].planed, "siteName[0].planed");
  const tableData = constructionUpload?.map?.((siteNames, index) => ({
    key: index.toString(), // Use index as key since there could be duplicate site names
    planned_D: siteName[0].planed,
    actual_D: siteName[0].actual, 
    progress_D: siteName[0].progress, 
    planned_I: siteName[1].planed,
    actual_I: siteName[1].actual, 
    progress_I: siteName[1].progress,
    progress_update: data.progress_update, 
    support_needed: data.support_needed, 
    imageUrl: data.construction_uplaod_picture?.map?.((item) => item.response.imageUrl),

  }));
  return (
    <div>
      <Table bordered dataSource={tableData} pagination={false} >
        <ColumnGroup className='main-head' title="Construction of Ashar Valley">
          <ColumnGroup className='main-head-man-op' title="Design Approval">
            <Column className='mini-head' title="Planned" dataIndex="planned_D" key="planned_D"/>
            <Column className='mini-head' title="Actual" dataIndex="actual_D" key="actual_D"/>
            <Column className='mini-head' title="Progress" dataIndex="progress_D" key="progress_D"/>
          </ColumnGroup>
          <ColumnGroup className='main-head-man-op' title="Infrastructure">
            <Column className='mini-head' title="Planned" dataIndex="planned_I" key="planned_I"/>
            <Column className='mini-head' title="Actual" dataIndex="actual_I" key="actual_I"/>
            <Column className='mini-head' title="Progress" dataIndex="progress_I" key="progress_I"/>
          </ColumnGroup>
          <Column className='mini-head' title="Progress Update" dataIndex="progress_update" key="progress_update"/>
          <Column className='mini-head' title="Support Needed" dataIndex="support_needed" key="support_needed"/>
          <Column className='mini-head' title="Pictures Upload" dataIndex="imageUrl" key="imageUrl"
          render={(imageUrl) => (
            <>
              {imageUrl?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    <a href={tag} target="_blank" >  
                    {tag}</a>
                  </Row>
                );
              })}
            </>
          )}/>
        </ColumnGroup>
      </Table>
    </div>
  );
};

export default ConstructionAsharValleyTable;