export const StepTemplate = ({ title, translation = {} }) => {
  return (
    <div
      className="break-inside"
      style={{
        backgroundColor: "#F6F8FA",
        display: "flex",
        justifyContent: "center",
        height: 47,
        alignItems: "center",
        marginTop: 10,
      }}
    >
      <p style={{ color: "#0A0D14", margin: 0, fontSize: 18 }}>
        {translation[title] || title || "-"}
      </p>
    </div>
  );
};
