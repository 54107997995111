import React from 'react';
import "./style.css"

import { Space, Table, Tag, Row, Divider } from 'antd';
const { Column, ColumnGroup } = Table;

const Training
 = ({ data }) => {
  const siteName = data.training_w3
  const tableData= siteName?.map?.((siteNames, index) => ({
    key: index.toString(), // Use index as key since there could be duplicate site names
    sites: siteNames.title,
    number_of_training_planned: siteNames.number_of_training_planned, // Assuming this should be dynamic, otherwise modify accordingly
    actual_provided: siteNames.actual_provided, // Assuming this should be dynamic, otherwise modify accordingly
    total_beneficiaries: siteNames.total_beneficiaries, // Assuming this should be dynamic, otherwise modify accordingly
    training_name: siteNames.training_list?.map?.((item) => item.training_name), // Assuming this should be dynamic, otherwise modify accordingly
    number_of_beneficiaries: siteNames.training_list?.map?.((item) => item.number_of_beneficiaries),
    nps: siteNames.training_list?.map?.((item) => item.nps),
    training_provider: siteNames.training_list?.map?.((item) => item.training_provider),

  }));

  return (
    <div>
      <Table bordered pagination={false} dataSource={tableData} >
      <ColumnGroup className='main-head' title="Trainings">

      <Column
    className="main-head-sites"
    title={<span className="title-style">Sites</span>}
    dataIndex="sites"
    key="sites"
    render={(text, record) => (
      <span className="sites-text">{text}</span>
    )}
  />
          <ColumnGroup className='main-head-man-op' title="Training">
            <Column className='mini-head' title="No. of trainings planned" dataIndex="number_of_training_planned" key="number_of_training_planned"/>
            <Column className='mini-head' title="Actual Provided" dataIndex="actual_provided" key="actual_provided"/>
            <Column className='mini-head' title="Total No. of beneficiaries" dataIndex="total_beneficiaries" key="total_beneficiaries" />
          </ColumnGroup>
          <ColumnGroup className='main-head-man-op' title="Training Lists">
            <Column className='mini-head' title="Training Name" dataIndex="training_name" key="training_name" 
            render={(training_name) => (
              <>
                {training_name?.map?.((tag) => {
                  return (
                    <Row style={{ marginTop: 10 }}>
                      {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                      {tag}
                    </Row>
                  );
                })}
              </>
            )}
            />
            <Column className='mini-head' title="No. of beneficiaries" dataIndex="number_of_beneficiaries" key="number_of_beneficiaries"
            render={(number_of_beneficiaries) => (
              <>
                {number_of_beneficiaries?.map?.((tag) => {
                  return (
                    <Row style={{ marginTop: 10 }}>
                      {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                      {tag}
                    </Row>
                  );
                })}
              </>
            )}
            />
            <Column className='mini-head' title="NPS" dataIndex="nps" key="nps" 
            render={(nps) => (
              <>
                {nps?.map?.((tag) => {
                  return (
                    <Row style={{ marginTop: 10 }}>
                      {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                      {tag}
                    </Row>
                  );
                })}
              </>
            )}
            />
            <Column className='mini-head' title="Provider of training" dataIndex="training_provider" key="training_provider"
            render={(training_provider) => (
              <>
                {training_provider?.map?.((tag) => {
                  return (
                    <Row style={{ marginTop: 10 }}>
                      {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                      {tag}
                    </Row>
                  );
                })}
              </>
            )}
            />
        </ColumnGroup>
        </ColumnGroup>

        
      </Table>
    </div>
  );
};

export default Training
;