import React from 'react';
import "./style.css"
import Logo from "./Images/logoPDF.svg"
import Ascend from "./Images/ascend.svg"
import Efficax from "./Images/efficax.svg"

import PHCReadinessTable from './Tables/PHCReadinessTable';
import WeekTable from './Tables/WeekTable';
import PatientJourneyEnhancementTable from './Tables/PatientJourneyEnhancementTable';
import Training from './Tables/Training';
import BrandingTable from './Tables/BrandingTable';
import ConstructionAsharValleyTable from './Tables/ConstructionAsharValleyTable';
import ScreeningTable from './Tables/ScreeningTable';
import ProgramGovernanceTable from './Tables/ProgramGovernanceTable';
import RiskAndMigrateTable from './Tables/RiskAndMigrateTable';
import AditionalRemarksTable from './Tables/AditionRemarksTable';

const ElmEodPdf = ({viewModal}) => {
  const {formData} = viewModal;
  return (
    <div>
      <div className="header">
            <div className="logo">
                <img src={Logo} alt="Logo" />
            </div>
        </div>
        <div className='PDF-body'>
        <div className='week'>
          <WeekTable data={formData} />
        </div>
        <div className='PHC-readiness-mobilisation'>
          <PHCReadinessTable phcData={formData} />
        </div>
        <div className='patient-journey-enhancement'> 
          <PatientJourneyEnhancementTable data={formData} />
        </div>
        <div className='training'> 
          <Training data={formData} />
        </div>
        <div className='branding'> 
          <BrandingTable data={formData} />
        </div>
        <div className='construction-asha-valley'> 
          <ConstructionAsharValleyTable data={formData} />
        </div>
        <div className='screening'> 
          <ScreeningTable data={formData} />
        </div>
        <div className='programg-governance'> 
          <ProgramGovernanceTable data={formData} />
        </div>
        <div className='risk'> 
          <RiskAndMigrateTable data={formData} />
        </div>
        <div className='aditional-remark'> 
          <AditionalRemarksTable data={formData} />
        </div>
        </div>
        <div className="critical-content"></div>
        <div className='maindiv-footer'>
        <div className="footer">
          <div className='image-foot'><span style={{paddingRight: 20 }}><img src={Efficax} alt="Logo" /></span><span><img src={Ascend} alt="Logo" /></span></div>
        </div>
        <hr className='hr'/>
        <div>
                <span className='footer-text'>This is the computer-generated report it doesn’t require any stamp or signature</span>
            </div>
            </div>
    </div>
  );
};

export default ElmEodPdf;