import { getByAddress } from "./helper";

export const Template3 = ({ field, props, comp, translation }) => {
  let address = getByAddress(field.address);

  let value =
    typeof field?.value === "object"
      ? field?.value?.label || field?.value?.[address] || "-"
      : field.value;

  switch (comp) {
    case "Upload":
      value = field?.value?.map?.((file, i) => (
        <>
          <a href={file.response?.imageUrl} target="_blank">
            {translation["Attachment"] || "Attachment"} # {i + 1}
          </a>
          <br />
        </>
      ));
      break;
  }

  return (
    <div style={styles.wrapper} className="break-inside ">
      <div style={styles.value_container} className="obli-question-body-value">
        <p style={styles.title}>{translation[field.title] || field.title}</p>

        {value || translation["N/A"]}
      </div>
    </div>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    justifyContent: "center",
    minHeight: 30,
    alignItems: "center",
    overflow: "hidden",
    // marginBottom: 10,
    flexDirection: "column",
    // marginTop: 10,
    // borderRadius: 10,
  },
  title_container: {
    width: "100%",
    minHeight: 40,
    backgroundColor: "#F6F8FA",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    margin: 0,
    fontSize: 16,
  },
  value_container: {
    width: "100%",
    padding: 10,
    backgroundColor: "#ecefee",
  },
};
