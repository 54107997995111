import React from "react";
import { Table } from "antd";
import HeaderObligation from "./components/obligation/header/HeaderObligation";
import moment from "moment";
import { getColorObl } from "./components/templates/helper";
import CorrectiveHeader from "./components/obligation/header/CorrectiveHeader";

const CompareReport = ({ viewModal, viewModal1 }) => {
  const { form, translation, submissionInfo } = viewModal;
  const { Column, ColumnGroup } = Table;

  let report1 = viewModal.answers.reduce((r, c) => {
    r[c.step + "." + c.no] = c.question;

    return r;
  }, {});

  let report2 = viewModal1.answers.reduce((r, c) => {
    r[c.step + "." + c.no] = c.question;

    return r;
  }, {});

  let allQuestions = viewModal?.questions
    .filter((question) => {
      let attribute = question.step?.trim?.() + "." + question.no;

      if (report1[attribute] === report2[attribute]) {
        return false;
      }

      return true;
    })
    .sort((a, b) => {
      let attribute = a.step?.trim?.() + "." + a.no;

      if (report1[attribute] === "N/A") {
        return -1;
      } else {
        return 1;
      }
    })
    .sort((a, b) => {
      let attribute = a.step?.trim?.() + "." + a.no;

      if (report1[attribute] === "Not Comply") {
        return -1;
      } else {
        return 1;
      }
    });

  const notComplyInReport1Count = allQuestions.filter((question) => {
    let attribute = question.step?.trim?.() + "." + question.no;
    return report1[attribute] === "Not Comply";
  });

  const complyCountInR2 = notComplyInReport1Count.filter((question) => {
    let attribute = question.step?.trim?.() + "." + question.no;
    return report2[attribute] === "Comply";
  }).length;

  const correctivePercentage = parseFloat(
    (complyCountInR2 / notComplyInReport1Count.length) * 100
  ).toFixed(2);

  const headerStyle = () => {
    return {
      style: {
        backgroundColor: "#008556",
        fontWeight: "bold",
        textAlign: "center",
      },
    };
  };

  const cellStyle = () => {
    return {
      style: {
        backgroundColor: "#f5f6f8", // Change to your desired cell background color
      },
    };
  };
  return (
    <div dir={"rtl"} style={{ padding: 10 }}>
      <div style={{ marginBottom: 10 }}>
        <HeaderObligation
          formName={form.name}
          submissionInfo={submissionInfo}
          translation={translation}
          isReportNumber={false}
        />
        <CorrectiveHeader
          percentage={
            notComplyInReport1Count.length > 0 ? correctivePercentage : " غير متاح"
          }
        />
      </div>

      <Table
        bordered
        dataSource={allQuestions}
        pagination={false}
        expandable={{ expandIconColumnIndex: -1 }}
      >
        <Column
          onCell={(record, rowIndex) => cellStyle()}
          onHeaderCell={headerStyle}
          title={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="">{translation["#"] || "#"}</span>
            </div>
          }
          dataIndex="no"
          key="no"
          align="right"
          width={"5%"}
          render={(text, record, index) => (
            <>
              <div className="sites-text" key={index} dir="rtl">
                {index + 1}
              </div>
            </>
          )}
        />
        <Column
          onCell={(record, rowIndex) => cellStyle()}
          onHeaderCell={headerStyle}
          title={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="">{translation["Standard"] || "Standard"}</span>
            </div>
          }
          dataIndex="title"
          key="title"
          align="right"
          width={"40%"}
          render={(text, record, index) => (
            <>
              <div className="sites-text" key={index} dir="rtl">
                {text}
              </div>
            </>
          )}
        />
        <Column
          title={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="">
                {translation["Report 1"] || "Report 1"} &nbsp; (%
                {viewModal.overallCompliance})
              </span>
              <span className="">
                {moment(viewModal?.submissionInfo?.created_at).format("lll")}
              </span>
            </div>
          }
          dataIndex="question"
          key="question"
          align="right"
          onCell={(record, rowIndex) => cellStyle()}
          onHeaderCell={headerStyle}
          render={(text, record, index) => {
            let attribute = record.step?.trim?.() + "." + record.no;
            let value = report1[attribute];
            return (
              <>
                <div
                  className="standard-obl-pill"
                  key={index}
                  dir="rtl"
                  style={{
                    width: "80%",
                    padding: 60,
                    height: 35,
                    backgroundColor: "#ecefee",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 100,
                    ...getColorObl(value),
                  }}
                >
                  <div
                    className="standard-obl-pill-dot"
                    style={{ background: getColorObl(value)?.color }}
                  ></div>
                  {translation[value] || value}
                </div>
              </>
            );
          }}
        />

        <Column
          onCell={(record, rowIndex) => cellStyle()}
          onHeaderCell={headerStyle}
          title={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="">
                {translation["Report 2"] || "Report 2"} &nbsp; (%
                {viewModal1.overallCompliance})
              </span>
              <span className="">
                {moment(viewModal1?.submissionInfo?.created_at).format("lll")}
              </span>
            </div>
          }
          dataIndex="question"
          key="question"
          align="right"
          render={(text, record, index) => {
            let attribute = record.step?.trim?.() + "." + record.no;
            let value = report2[attribute];
            return (
              <>
                <div
                  className="standard-obl-pill"
                  key={index}
                  dir="rtl"
                  style={{
                    width: "80%",
                    padding: 60,
                    height: 35,
                    backgroundColor: "#ecefee",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 100,
                    ...getColorObl(value),
                  }}
                >
                  <div
                    className="standard-obl-pill-dot"
                    style={{ background: getColorObl(value)?.color }}
                  ></div>
                  {translation[value] || value}
                </div>
              </>
            );
          }}
        />
      </Table>

      <div className="critical-content"></div>
    </div>
  );
};

export default CompareReport;
