import React from 'react';
import "./style.css"

import { Space, Table, Tag, Row, Divider } from 'antd';

const { Column, ColumnGroup } = Table;

const Campaign
 = ({data}) => {

  const siteName = data.Campaign
  const tableData = siteName?.map?.((siteNames, index) => ({
    key: index.toString(), 
    campaign_name : siteNames.campaign_name,
    campaign_location : siteNames.campaign_location,
    campaign_date_range : siteNames.campaign_date_range,
    campaign_target_population : siteNames.campaign_target_population,
    campaign_duration : siteNames.campaign_duration,
    campaign_launch_date : siteNames.campaign_launch_date,
    campaign_activites : siteNames.campaign_activites, 
    campaign_observations : siteNames.campaign_observations, 
    imageUrl: siteNames.campaign_upload?.map?.((item) => item.response.imageUrl), 

  }));
  return (
    <div>
  <Table bordered dataSource={tableData} pagination={false}>
    <ColumnGroup className='main-head' title="Support Campaign">
      <Column className='mini-head' title="Name" dataIndex="campaign_name" key="campaign_name"
      />
      <Column className='mini-head' title="Location" dataIndex="campaign_location" key="campaign_location"/>
      <Column className='mini-head' title="Date Range" dataIndex="campaign_date_range" key="campaign_date_range"/>
      <Column className='mini-head' title="Target Population" dataIndex="campaign_target_population" key="campaign_target_population"/>
      <Column className='mini-head' title="Duration" dataIndex="campaign_duration" key="campaign_duration"/>
      <Column className='mini-head' title="Launch Date" dataIndex="campaign_launch_date" key="campaign_launch_date"/>
      <Column className='mini-head' title="Activites" dataIndex="campaign_activites" key="campaign_activites"/>
      <Column className='mini-head' title="Observations" dataIndex="campaign_observations" key="campaign_observations"/>
      <Column className='mini-head' title="Pictures Upload" dataIndex="imageUrl" key="imageUrl"
          render={(imageUrl) => (
            <>
              {imageUrl?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    <a href={tag} target="_blank" >  
                    {tag}</a>
                  </Row>
                );
              })}
            </>
          )}/>
    </ColumnGroup>
  </Table>
        </div>
  );
};

export default Campaign
;