import React from 'react';
import "./style.css"

import { Space, Table, Tag, Row, Divider } from 'antd';
const { Column, ColumnGroup } = Table;

const AditionalRemarksTable
 = ({ data }) => {
  const tableData = [{
    // key: key,
    additional_remarks_notes: data.additional_remarks_notes, 
    imageUrl: data.addition_remarks_upload_picture?.map?.((item) => item.response.imageUrl),

  }];



  return (
    <div>
      <Table bordered dataSource={tableData} pagination={false} >
        <ColumnGroup className='main-head' title="Aditional Remarks">
          <Column className='mini-head' title="Notes" dataIndex="additional_remarks_notes" key="additional_remarks_notes"/>
          <Column className='mini-head' title="Pictures Upload" dataIndex="imageUrl" key="imageUrl"
          render={(imageUrl) => (
            <>
              {imageUrl?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    <a href={tag} target="_blank" >  
                    {tag}</a>
                  </Row>
                );
              })}
            </>
          )}/>
        </ColumnGroup>
      </Table>
    </div>
  );
};

export default AditionalRemarksTable
;