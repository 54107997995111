import React from 'react';
import "./style.css"

import { Space, Table, Tag, Row, Divider } from 'antd';
const { Column, ColumnGroup } = Table;

const PHCReadinessTable = ({ phcData }) => {
  const siteName = phcData.mobilization_w11
  const data = siteName.map?.((siteNames, index) => ({
    key: index.toString(), // Use index as key since there could be duplicate site names
    sites: siteNames.title,
    op_hr: siteNames.operation_hours, // Assuming this should be dynamic, otherwise modify accordingly
    supervisor: siteNames.manpower_w11?.map?.((item) => item?.supervisor), // Assuming this should be dynamic, otherwise modify accordingly
    on_ground: siteNames.manpower_w11?.map?.((item) => item?.on_ground), // Assuming this should be dynamic, otherwise modify accordingly
    patient_reception: siteNames.manpower_w11?.map?.((item) => item.patient_reception), // Assuming this should be dynamic, otherwise modify accordingly
    patient_navigator: siteNames.manpower_w11?.map?.((item) => item.patient_navigator),
    patients_served: siteNames.operations_w11?.map?.((item) => item.patient_served),
    resident_served: siteNames.operations_w11.map?.((item) => item.resident_served),
    citizen_served: siteNames.operations_w11.map?.((item) => item.citizen_served),

  }));
  return (
    <div>
      <Table bordered dataSource={data} pagination={false} >
      <Column
    className="main-head-sites"
    title={<span className="title-style">Sites</span>}
    dataIndex="sites"
    key="sites"
    render={(text, record) => (
      <span className="sites-text">{text}</span>
    )}
  />
        <Column
    className="main-head"
    title={<span className="title-style">Operation Hr</span>}
    dataIndex="op_hr"
    key="op_hr"
    render={(text, record) => (
      <span className="value-style">{text}</span>
    )}
  />
        <ColumnGroup className='main-head' title="PHC Readiness & mobilisation">
          <ColumnGroup className='main-head-man-op' title="Manpower">
            <Column className='mini-head' title="Supervisor" dataIndex="supervisor" key="supervisor"
              render={(on_ground) => (
                <>
                  {on_ground?.map?.((tag) => {
                    return (
                      <Row style={{ marginTop: 10 }}>
                        {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                        {tag}
                      </Row>
                    );
                  })}
                </>
              )}
            />
            <Column className='mini-head' title="On Ground" dataIndex="on_ground" key="on_ground" 
             render={(supervisor) => (
              <>
                {supervisor?.map?.((tag) => {
                  return (
                    <Row style={{ marginTop: 10 }}>
                      {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                      {tag}
                    </Row>
                  );
                })}
              </>
            )}/>
            <Column className='mini-head' title="Patient Reception" dataIndex="patient_reception" key="patient_reception" 
             render={(patient_reception) => (
              <>
                {patient_reception?.map?.((tag) => {
                  return (
                    <Row style={{ marginTop: 10 }}>
                      {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                      {tag}
                    </Row>
                  );
                })}
              </>
            )}/>
            <Column className='mini-head' title="Patient Navigator" dataIndex="patient_navigator" key="patient_navigator"
             render={(patient_navigator) => (
              <>
                {patient_navigator?.map?.((tag) => {
                  return (
                    <Row style={{ marginTop: 10 }}>
                      {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                      {tag}
                    </Row>
                  );
                })}
              </>
            )} />
          </ColumnGroup>
          <ColumnGroup className='main-head-man-op' title="Operation">
            <Column className='mini-head' title="Patients Served" dataIndex="patients_served" key="patients_served" 
            render={(patients_served) => (
              <>
                {patients_served?.map?.((tag) => {
                  return (
                    <Row style={{ marginTop: 10 }}>
                      {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                      {tag}
                    </Row>
                  );
                })}
              </>
            )} />
            <Column className='mini-head' title="Citizen Served" dataIndex="citizen_served" key="citizen_served"
            render={(citizen_served) => (
              <>
                {citizen_served?.map?.((tag) => {
                  return (
                    <Row style={{ marginTop: 10 }}>
                      {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                      {tag}
                    </Row>
                  );
                })}
              </>
            )} />
            <Column className='mini-head' title="Resident Served" dataIndex="resident_served" key="resident_served" 
            render={(resident_served) => (
              <>
                {resident_served?.map?.((tag) => {
                  return (
                    <Row style={{ marginTop: 10 }}>
                      {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                      {tag}
                    </Row>
                  );
                })}
              </>
            )}/>
          </ColumnGroup>
        </ColumnGroup>
      </Table>
    </div>
  );
};

export default PHCReadinessTable;