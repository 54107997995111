import React from 'react';
import "./style.css"

import { Space, Table, Tag, Row, Divider } from 'antd';
const { Column, ColumnGroup } = Table;

const RiskAndMigrateTable = ({ data }) => {
  const siteName = data.key_risks_and_mitigations
  const tableData = siteName?.map?.((siteNames, index) => ({
    key: index.toString(),
    sites: siteNames.rnm_site,
    risk: siteNames.rnm_number,
    mitigration: siteNames.rnm_mitigation,
    likehood: siteNames.rnm_likehood,
    accountability: siteNames.rnm_accountibility,


  }));
  return (
    <div>
      <Table bordered dataSource={tableData} pagination={false} >
      <Column
    className="main-head-sites"
    title={<span className="title-style">Sites</span>}
    dataIndex="sites"
    key="sites"
    render={(text, record) => (
      <span className="sites-text">{text}</span>
    )}
  />
        <ColumnGroup className='main-head' title="Risks and Mitigations">
          <Column className='mini-head' title="Risk" dataIndex="risk" key="risk"
          />
          <Column className='mini-head' title="Mitigation" dataIndex="mitigration" key="mitigration"/>
          <Column className='mini-head' title="Likelihood" dataIndex="likehood" key="likehood"
          />
          <Column className='mini-head' title="Accountability" dataIndex="accountability" key="accountability"
          />
        </ColumnGroup>
      </Table>
    </div>
  );
};

export default RiskAndMigrateTable;