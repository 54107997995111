import React from 'react';
import "./style.css"

import { Space, Table, Tag, Row, Divider } from 'antd';
const { Column, ColumnGroup } = Table;

const Training
 = ({ data }) => {
  const siteName = data.training_w3
  const tableData= siteName?.map?.((siteNames, index) => ({
    key: index.toString(), // Use index as key since there could be duplicate site names
    sites: siteNames.title,
    training_progress: siteNames.training_progress, 
    imageUrl: siteNames.training_upload?.map((item) => item?.response?.imageUrl), 
    training_name: siteNames.step_for_training?.map?.((item) => item.training_name), 
    training_date: siteNames.step_for_training?.map?.((item) => item.training_date),
    nurse_trained: siteNames.step_for_training?.map?.((item) => item.nurse_trained),
    doctor_trained: siteNames.step_for_training?.map?.((item) => item.doctor_trained),
    training_provider: siteNames.step_for_training?.map?.((item) => item.training_provider),

  }));

  return (
    <div>
      <Table bordered pagination={false} dataSource={tableData} >
      <ColumnGroup className='main-head' title="Trainings">

      <Column
    className="main-head-sites"
    title={<span className="title-style">Sites</span>}
    dataIndex="sites"
    key="sites"
    render={(text, record) => (
      <span className="sites-text">{text}</span>
    )}
  />
          <ColumnGroup className='main-head-man-op' title="Training">
            <Column className='mini-head' title="No. of trainings planned" dataIndex="training_progress" key="training_progress"/>
          </ColumnGroup>
          <ColumnGroup className='main-head-man-op' title="Training Lists">
            <Column className='mini-head' title="Training Name" dataIndex="training_name" key="training_name" 
            render={(training_name) => (
              <>
                {training_name?.map?.((tag) => {
                  return (
                    <Row style={{ marginTop: 10 }}>
                      {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                      {tag}
                    </Row>
                  );
                })}
              </>
            )}
            />
            <Column className='mini-head' title="Training Date" dataIndex="training_date" key="training_date"
            render={(training_date) => (
              <>
                {training_date?.map?.((tag) => {
                  return (
                    <Row style={{ marginTop: 10 }}>
                      {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                      {tag}
                    </Row>
                  );
                })}
              </>
            )}
            />
            <Column className='mini-head' title="Nurse Trained" dataIndex="nurse_trained" key="nurse_trained" 
            render={(nurse_trained) => (
              <>
                {nurse_trained?.map?.((tag) => {
                  return (
                    <Row style={{ marginTop: 10 }}>
                      {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                      {tag}
                    </Row>
                  );
                })}
              </>
            )}
            />
             <Column className='mini-head' title="Doctor Trained" dataIndex="doctor_trained" key="doctor_trained" 
            render={(doctor_trained) => (
              <>
                {doctor_trained?.map?.((tag) => {
                  return (
                    <Row style={{ marginTop: 10 }}>
                      {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                      {tag}
                    </Row>
                  );
                })}
              </>
            )}
            />
            <Column className='mini-head' title="Provider of training" dataIndex="training_provider" key="training_provider"
            render={(training_provider) => (
              <>
                {training_provider?.map?.((tag) => {
                  return (
                    <Row style={{ marginTop: 10 }}>
                      {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                      {tag}
                    </Row>
                  );
                })}
              </>
            )}
            />
        </ColumnGroup>
        <Column className='mini-head' title="Pictures Upload" dataIndex="imageUrl" key="imageUrl"
          render={(imageUrl) => (
            <>
              {imageUrl?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    <a href={tag} target="_blank" >  
                    {tag}</a>
                  </Row>
                );
              })}
            </>
          )}/>
        </ColumnGroup>

        
      </Table>
    </div>
  );
};

export default Training
;