import React from 'react';
import "./style.css"

import { Space, Table, Tag } from 'antd';
const { Column, ColumnGroup } = Table;

const PatientJourneyEnhancementTable = ({data}) => {

  const siteName = data.patient_journey_w2
  const tableData = siteName.map((siteNames, index) => ({
    key: index.toString(), 
    sites: siteNames.title,
    complains_937: siteNames.complains_937, 
    press_ganey_score: siteNames.press_ganey_score, 
    sample_size: siteNames.sample_size, 
    sent_to_beneficiaries: siteNames.sent_to_beneficiaries, 
    filled_survey: siteNames.filled_survey,
    number_of_complains_received: siteNames.number_of_complains_received,
    number_of_interviews_conducted: siteNames.number_of_interviews_conducted,

  }));
  return (
    <div>
  <Table bordered dataSource={tableData} pagination={false}>
  <Column className='main-head-sites' title="Sites" dataIndex="sites" key="sites" />
    <ColumnGroup className='main-head' title="Patient Journey Enhancement">
      <Column className='mini-head' title="973" dataIndex="complains_937" key="complains_937" />
      <Column className='mini-head' title="Press Ganey Score" dataIndex="press_ganey_score" key="press_ganey_score" />
      <Column className='mini-head' title="Sample Size" dataIndex="sample_size" key="sample_size" />
      <Column className='mini-head' title="No. of Surveys Sent to Beneficiaries" dataIndex="sent_to_beneficiaries" key="sent_to_beneficiaries" />
      <Column className='mini-head' title="No. of filled survey" dataIndex="filled_survey" key="filled_survey" />
      <Column className='mini-head' title="No. of complains received on social media platform" dataIndex="number_of_complains_received" key="number_of_complains_received" />
      <Column className='mini-head' title="No. of interview conducted" dataIndex="number_of_interviews_conducted" key="number_of_interviews_conducted" />
    </ColumnGroup>
  </Table>
        </div>
  );
};

export default PatientJourneyEnhancementTable;