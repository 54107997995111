export const getSchemaData = (id) => {
  const params = new URLSearchParams(new URL(window.location.href).search);
  const token = params.get("token");
  const url = params.get("url");

  return fetch(`${url}extractPdfData/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      return data;
      // Handle the data here
    })
    .catch((error) => {
      console.error("There was a problem with the fetch operation:", error);
    });
  // return axios.get(`${api_url}/api/surveys/answers/${submitionId}`);
};
