import React from 'react';
import "./style.css"
import Logo from "./Images/logoPDF.svg"
import Ascend from "./Images/ascend.svg"
import Efficax from "./Images/efficax.svg"
import WeekTable from './Tables/WeekTable';
import PHCReadinessTable from './Tables/PHCReadinessTable';
import ManpowerOpertions from './Tables/ManpowerOperations';
import Training from './Tables/Training';
import Campaign from './Tables/Campaign';
import RiskAndMigrateTable from './Tables/RiskAndMigrateTable';
import AchievementsStatusPlannedActivitesTable from './Tables/AchievementsStatusPlannedActivitesTable';



const ElmEodPdf = ({viewModal}) => {
  const {formData} = viewModal;
  return (
    <div>
      <div className="header">
            <div className="logo">
                <img src={Logo} alt="Logo" />
            </div>
        </div>
        <div className='PDF-body'>
        <div className='week'>
          <WeekTable data={formData} />
        </div>
        <div className='PHC-readiness-mobilisation'>
          <PHCReadinessTable phcData={formData} />
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className='manpower-opertaions'>
          <ManpowerOpertions phcData={formData} />
        </div>
        <br/>
        <br/>
        <div className='training'>
          <Training data={formData} />
        </div>
        <div className='planned'>
          <AchievementsStatusPlannedActivitesTable data={formData} />
        </div>
        <div className='risk'> 
          <RiskAndMigrateTable data={formData} />
        </div>
        <div className='campaign'> 
          <Campaign data={formData} />
        </div>
        </div>
        <div className="critical-content"></div>
        <div className='maindiv-footer'>
        <div className="footer">
          <div className='image-foot'><span style={{paddingRight: 20 }}><img src={Efficax} alt="Logo" /></span><span><img src={Ascend} alt="Logo" /></span></div>
        </div>
        <hr className='hr'/>
        <div>
                <span className='footer-text'>This is the computer-generated report it doesn’t require any stamp or signature</span>
            </div>
            </div>
    </div>
  );
};

export default ElmEodPdf;