import React from 'react';
import "./style.css"
import { Col, Divider, Row } from 'antd';

import { Space, Table, Tag } from 'antd';
const { Column, ColumnGroup } = Table;

const WeekTable = ({data}) => {

  
  console.log(data , "weekdata");
  
  const customNoDataLocale = {
    emptyText: '', // Customize the "no data" text
  };
  return (
    <div>
      <Row className='week-plan'>Week Plan</Row>
    <Row>
      <Col className='week-plan-aw ' span={6} order={4}>
        {data.week_start}
      </Col>
      <Col className='week-plan-qs' span={6} order={3}>
      Start Date 
      </Col>
      <Col className='week-plan-aw ' span={6} order={2}>
        {data.year_and_month}   </Col>
      <Col className='week-plan-qs' span={6} order={1}>
      Year & Month
      </Col>
    </Row>
    <Row>
      <Col className='week-plan-aw ' span={6} order={4}>
           {data.week_end} </Col>
      <Col className='week-plan-qs' span={6} order={3}>
      End Date 
      </Col>
      <Col className=' week-plan-aw ' span={6} order={2}>
         {data.week}  </Col>
      <Col className='week-plan-qs' span={6} order={1}>
      Week
      </Col>
    </Row>
        </div>
  );
};

export default WeekTable;