import { startCase } from "lodash";
import {
  getByAddress,
  getColor,
  getParrentAddress,
  getStandard,
  isArabic,
  getStandardClrObl,
  getColorObl,
} from "./helper";
import "./style.css";
import classNames from "classnames";

export const TemplateObligation = ({
  field,
  props,
  allData,
  translation = {},
}) => {
  let address = getByAddress(field.address);
  let parrentAddress = getParrentAddress(field.address);

  const value =
    typeof field?.value === "object"
      ? field?.value?.[address] || field?.value?.label || "-"
      : field.value;
  const colorStyle = getColor(value);
  const title = field?.value?.[address]?.title || field.title;
  let standard = getStandard(allData?.[parrentAddress]?.value || field);

  if (title === "هل القسم متاح؟" && value === "Yes") {
    return null;
  }

  return (
    <div style={styles.wrapper} className="break-inside obligation-temp">
      {!!standard && (
        <div style={{ ...styles.value_container }} classNames="">
          <p
            className="value_container-obl"
            dir={isArabic(standard) ? "rtl" : "ltr"}
          >
            {translation[startCase(standard)] || startCase(standard) || "-"}

            <div className="standard-obl-pill-dot" />
          </p>
        </div>
      )}

      <div
        style={{
          ...styles.title_container,
          ...(!!standard && {
            "border-left": "1px solid #cdd0d5",
            "border-right": "1px solid #cdd0d5",
          }),
        }}
        className="title-contianer"
        dir={isArabic(title) ? "rtl" : "ltr"}
      >
        <p style={styles.title}>{title}</p>
      </div>

      <div style={styles.standard} className="standard-obl">
        <p
          className="standard-obl-pill"
          style={{ ...getColorObl(value) }}
          dir={isArabic(value) ? "rtl" : "ltr"}
        >
          {translation[value] || value || "-"}

          <div
            className="standard-obl-pill-dot"
            style={{ background: getColorObl(value)?.color }}
          />
        </p>
      </div>
    </div>
  );
};

const styles = {
  wrapper: {
    width: "100%",
    backgroundColor: "#F5F6F8",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    marginBottom: 10,
    marginTop: 10,
    borderRadius: 5,
    width: "100%",
    justifyContent: "space-between",
    height: "100%",
    position: "relative",
    alignItems: "stretch",
  },
  title_container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    alignSelf: "center",
    paddingLeft: 10,
    padding: 10,
  },
  title: {
    margin: 0,
    fontSize: 14,
    color: "#0A0D14",
  },
  value_container: {
    width: "35%",
    // padding: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  standard: {
    width: "30%",
    // padding: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
