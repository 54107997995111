import React from 'react';
import "./style.css"
import moment from 'moment';


import { Space, Table, Tag, Row, Divider } from 'antd';
const { Column, ColumnGroup } = Table;

const ManpowerOpertions = ({ phcData }) => {

  

  const siteName = phcData.manpower_opertaions
  const data = siteName.map?.((siteNames, index) => ({
    key: index.toString(), // Use index as key since there could be duplicate site names
    sites: siteNames.title,
    man_planned: siteNames.man_planned,
    man_actual: siteNames.man_actual,
    op_shift: siteNames.operations.map((item) => item.op_shift),
    op_personnel: siteNames.operations.map((item) => item.op_personnel),
    date_one: siteNames.operations.map((item) => item.date_one),
    date_two: siteNames.operations.map((item) => item.date_two),
    date_three: siteNames.operations.map((item) => item.date_three),
    date_four: siteNames.operations.map((item) => item.date_four),
    date_five: siteNames.operations.map((item) => item.date_five),
    date_six: siteNames.operations.map((item) => item.date_six),
    date_seven: siteNames.operations.map((item) => item.date_seven),

    
    

  }));

  const   getDate = (dateString, noOfDays) => {
    let date = moment(new Date(dateString)).add(noOfDays, "days")
    return date.format("ll")
  }

  return (
    <div>
      <Table bordered dataSource={data} pagination={false} >
      <Column
    className="main-head-sites"
    title={<span className="title-style">Sites</span>}
    dataIndex="sites"
    key="sites"
    render={(text, record) => (
      <span className="sites-text">{text}</span>
    )}
  />
  <ColumnGroup className='main-head' title="Manpower & Operation">
          <ColumnGroup className='main-head-man-op' title="Manpower">
            <Column className='mini-head' title="Planned" dataIndex="man_planned" key="man_planned" 
            />
            <Column className='mini-head' title="Actual" dataIndex="man_actual" key="man_actual"
             />
            </ColumnGroup>
            <ColumnGroup className='main-head-man-op' title="Operation">
            <Column className='mini-head' title="Shift" dataIndex="op_shift" key="op_shift"
            render={(op_shift) => (
              <>
                {op_shift?.map?.((tag) => {
                  return (
                    <Row style={{ marginTop: 10 }}>
                      {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                      {tag}
                    </Row>
                  );
                })}
              </>
            )}/>
          <Column className='mini-head' title="Personnel" dataIndex="op_personnel" key="op_personnel"
          render={(op_personnel) => (
            <>
              {op_personnel?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}/>
          <Column className='mini-head' title={getDate(phcData.week_start, 0)} dataIndex="date_one" key="date_one"
          render={(date_one) => (
            <>
              {date_one?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}/>
          <Column className='mini-head' title={getDate(phcData.week_start, 1)} dataIndex="date_two" key="date_two"
          render={(date_two) => (
            <>
              {date_two?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}/>
          <Column className='mini-head' title={getDate(phcData.week_start, 2)} dataIndex="date_three" key="date_three"
          render={(date_three) => (
            <>
              {date_three?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}/>
          <Column className='mini-head' title={getDate(phcData.week_start, 3)} dataIndex="date_four" key="date_four"
          render={(date_four) => (
            <>
              {date_four?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}/>
          <Column className='mini-head' title={getDate(phcData.week_start, 4)} dataIndex="date_five" key="date_five"
          render={(date_five) => (
            <>
              {date_five?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}/>
          <Column className='mini-head' title={getDate(phcData.week_start, 5)} dataIndex="date_six" key="date_six"
          render={(date_six) => (
            <>
              {date_six?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}/>
          <Column className='mini-head' title={getDate(phcData.week_start, 6)} dataIndex="date_seven" key="date_seven"
          render={(date_seven) => (
            <>
              {date_seven?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}/>
            </ColumnGroup>
          
        </ColumnGroup>
        
      </Table>
    </div>
  );
};

export default ManpowerOpertions;