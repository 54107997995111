




import React from 'react';
import "./style.css"

import { Space, Table, Tag, Row, Divider } from 'antd';
const { Column, ColumnGroup } = Table;

const BrandingTable = ({ data }) => {
  const siteName = data.branding_w4
  console.log(data,"siteb");
  const tableData = siteName?.map?.((siteNames, index) => ({
    key: index.toString(), // Use index as key since there could be duplicate site names
    sites: siteNames.title,
    location: siteNames.phases_w4?.map?.((item) => item?.location), // Assuming this should be dynamic, otherwise modify accordingly
    start_date: siteNames.phases_w4?.map?.((item) => item?.start_date), // Assuming this should be dynamic, otherwise modify accordingly
    end_date: siteNames.phases_w4?.map?.((item) => item?.end_date), // Assuming this should be dynamic, otherwise modify accordingly
    duration: siteNames.phases_w4?.map?.((item) => item.duration), // Assuming this should be dynamic, otherwise modify accordingly
    branding_book: siteNames.phases_w4?.map?.((item) => item.branding_book),
    approval_on_branding_book: siteNames.phases_w4?.map?.((item) => item?.approval_on_branding_book),
    printing_plan: siteNames.phases_w4?.map?.((item) => item?.printing_plan),
    printing_actual: siteNames.phases_w4?.map?.((item) => item?.printing_actual),
    execution_actual: siteNames.phases_w4?.map?.((item) => item?.execution_actual),
    execution_plan: siteNames.phases_w4?.map?.((item) => item?.execution_plan),
    finishing_plan: siteNames.phases_w4?.map?.((item) => item?.finishing_plan),
    finishing_actual: siteNames.phases_w4?.map?.((item) => item?.finishing_actual),

  }));
  return (
    <div>
      <Table bordered dataSource={tableData} pagination={false} >
      <Column
    className="main-head-sites"
    title={<span className="title-style">Sites</span>}
    dataIndex="sites"
    key="sites"
    render={(text, record) => (
      <span className="sites-text">{text}</span>
    )}
  />
        <ColumnGroup className='main-head' title="Branding">
        <Column className='mini-head' title="Location" dataIndex="location" key="location"
        render={(location) => (
            <>
              {location?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}
        />
        <Column className='mini-head' title="Start Date" dataIndex="start_date" key="start_date" 
        render={(start_date) => (
            <>
              {start_date?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}
        />
        <Column className='mini-head' title="End Date" dataIndex="end_date" key="end_date" 
        render={(end_date) => (
            <>
              {end_date?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}
        />
        <Column className='mini-head' title="Duration" dataIndex="duration" key="duration"
        render={(duration) => (
            <>
              {duration?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}
        />
        <Column className='mini-head' title="Branding Book" dataIndex="branding_book" key="branding_book" 
        render={(branding_book) => (
            <>
              {branding_book?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}
        />
        <Column className='mini-head' title="Approval on Branding Book" dataIndex="approval_on_branding_book" key="approval_on_branding_book" 
        render={(approval_on_branding_book) => (
            <>
              {approval_on_branding_book?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}
        />
        <ColumnGroup className='main-head-man-op' title="Printing">
        <Column className='mini-head' title="Planned" dataIndex="printing_plan" key="printing_plan"
        render={(printing_plan) => (
            <>
              {printing_plan?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}
        />
        <Column className='mini-head' title="Actual" dataIndex="printing_actual" key="printing_actual" 
        render={(printing_actual) => (
            <>
              {printing_actual?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}
        />
        </ColumnGroup>
        <ColumnGroup className='main-head-man-op' title="Execution">
        <Column className='mini-head' title="Planned" dataIndex="execution_plan" key="execution_plan" 
        render={(execution_plan) => (
            <>
              {execution_plan?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}
        />
        <Column className='mini-head' title="Actual" dataIndex="execution_actual" key="execution_actual"
        render={(execution_actual) => (
            <>
              {execution_actual?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}
        />
        </ColumnGroup>
        <ColumnGroup className='main-head-man-op' title="Finishing">
        <Column className='mini-head' title="Planned" dataIndex="finishing_plan" key="finishing_plan"
        render={(finishing_plan) => (
            <>
              {finishing_plan?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}
        />
        <Column className='mini-head' title="Actual" dataIndex="finishing_actual" key="finishing_actual"
        render={(finishing_actual) => (
            <>
              {finishing_actual?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}
        />
        </ColumnGroup>

        </ColumnGroup>
      </Table>
    </div>
  );
};

export default BrandingTable;