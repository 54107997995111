export const getByAddress = (address) => {
  return String(address).split(".").pop();
};

export const getParrentAddress = (address) => {
  if (address) {
    let splitString = String(address).split(".");
    // Finding the last index of a substring containing only digits
    let lastIndex = Math.max(
      ...splitString.map((sub, index) => (/\d+/.test(sub * 1) ? index : -1))
    );
    // Joining the split string up to the last occurrence of a number
    return splitString.slice(0, lastIndex + 1).join(".");
  }
  return "";
};

export const isArabic = (text) => {
  let arabicStringSearch = /[\u0600-\u06FF]/;
  return arabicStringSearch.test(text);
};

export const getStandard = (item) => {
  if (item?.Likelihood) {
    const riskRating = item.Likelihood * item.Impact || 0;
    if (riskRating >= 0 && riskRating <= 2) {
      return "Low";
    } else if (riskRating >= 3 && riskRating <= 5) {
      return "Medium";
    } else if (riskRating >= 6 && riskRating <= 9) {
      return "MediumHigh";
    } else if (riskRating >= 10 && riskRating <= 14) {
      return "High";
    } else {
      return "Critical";
    }
  }
  return "Low";
};

export const getColor = (value) => {
  switch (value) {
    case "Yes":
      return { background: "#b1aed9", color: "#413771" };

    case "Comply":
      return { background: "#dbebe6", color: "#5dbb7b" };
    case "No":
    case "Not Comply":
      return { background: "#f4e4e4", color: "#dc3636" };
    case "N/A":
    case "Not Applicable":
      return { background: "#ffecb3", color: "#ffc107" };
    case "Not Targeted":
      return { background: "#A4ABB6", color: "black" };

    default:
      return {};
  }
};
export const getColorObl = (value) => {
  switch (value) {
    case "Yes":
    case "Comply":
      return { background: "#CBF5E5", color: "#176448" };
    case "No":
    case "Not Comply":
      return { background: "#FDEDF0", color: "#DF1C41" };

    case "N/A":
    case "Not Applicable":
      return { background: "#FBDFB1", color: "#693D11" };
    case "Not Targeted":
      return { background: "#A4ABB6", color: "black" };

    default:
      return {};
  }
};

export const getStandardClrObl = (item) => {
  switch (item) {
    case "Critical":
      return { background: "#AF1D38" };

    case "Medium":
      return { background: "#F17B2C" };

    case "Low":
      return { background: "#38C793" };

    case "High":
      return { background: "#DF1C41" };

    default:
      return {};
  }
};
