import React from 'react';
import "./style.css"

import { Space, Table, Tag, Row, Divider } from 'antd';
const { Column, ColumnGroup } = Table;

const AchievementsStatusPlannedActivitesTable = ({ data }) => {
  const tableData = [{
    // key: key, // Use index as key since there could be duplicate site names
    achievements_to_date_text: data.achievements_to_date_text, 
    planned_activites_for_next_week_text: data.planned_activites_for_next_week_text, 
    achievements_to_date_upload: data.achievements_to_date_upload?.map?.((item) => item.response.imageUrl),
    planned_activites_for_next_week_upload: data.planned_activites_for_next_week_upload?.map?.((item) => item.response.imageUrl),


  }];
  return (
    <div>
      <Table bordered dataSource={tableData} pagination={false} >
        <ColumnGroup className='main-head' title="Achievements Status & Planned Activites">
          <Column className='mini-head' title="Achievements To-Date" dataIndex="achievements_to_date_text" key="achievements_to_date_text"/>
          <Column className='mini-head' title="Planned Activites for Next Week" dataIndex="achievements_to_date_upload" key="achievements_to_date_upload"
          render={(achievements_to_date_upload) => (
            <>
              {achievements_to_date_upload?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    <a href={tag} target="_blank" >  
                    {tag}</a>
                  </Row>
                );
              })}
            </>
          )}/>
          
          <Column className='mini-head' title="Support Needed" dataIndex="planned_activites_for_next_week_text" key="planned_activites_for_next_week_text"/>
          <Column className='mini-head' title="Pictures Upload" dataIndex="planned_activites_for_next_week_upload" key="planned_activites_for_next_week_upload"
          render={(planned_activites_for_next_week_upload) => (
            <>
              {planned_activites_for_next_week_upload?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    <a href={tag} target="_blank" >  
                    {tag}</a>
                  </Row>
                );
              })}
            </>
          )}/>
        </ColumnGroup>
      </Table>
    </div>
  );
};

export default AchievementsStatusPlannedActivitesTable;