import React from 'react';
import "./style.css"

import { Space, Table, Tag, Row, Divider } from 'antd';
const { Column, ColumnGroup } = Table;

const PHCReadinessTable = ({ phcData }) => {
  const siteName = phcData.mobilization_w11
  const data = siteName.map?.((siteNames, index) => ({
    key: index.toString(), // Use index as key since there could be duplicate site names
    sites: siteNames.title,
    citizin_pps: siteNames.citizin_pps,
    visitors_pps: siteNames.visitors_pps,
    min_pps: siteNames.min_pps,
    total_pps: siteNames.total_pps,
    citizin_rps: siteNames.citizin_rps,
    visitors_rps: siteNames.visitors_rps,
    min_rps: siteNames.min_rps,
    total_rps: siteNames.total_rps,
    CEU: siteNames.CEU,
    CME: siteNames.CME,
    RN_comp: siteNames.RN_comp,

  }));
  return (
    <div>
      <Table bordered dataSource={data} pagination={false} >
      <Column
    className="main-head-sites"
    title={<span className="title-style">Sites</span>}
    dataIndex="sites"
    key="sites"
    render={(text, record) => (
      <span className="sites-text">{text}</span>
    )}
  />
  <ColumnGroup className='main-head' title="Clinical Interventions">
          <ColumnGroup className='main-head-man-op' title="Physician Pts Seen">
            <Column className='mini-head' title="Citizin Patients" dataIndex="citizin_pps" key="citizin_pps"  />
            <Column className='mini-head' title="Visitors Patients" dataIndex="visitors_pps" key="visitors_pps" />
            <Column className='mini-head' title="Average TAT" dataIndex="min_pps" key="min_pps" />
            {/* <Column className='mini-head' title="Total" dataIndex="total_pps" key="total_pps" /> */}
          </ColumnGroup>
          <ColumnGroup className='main-head-man-op' title="RN Pts Seen">
          <Column className='mini-head' title="Citizin Patients" dataIndex="citizin_rps" key="citizin_rps" />
            <Column className='mini-head' title="Visitors Patients" dataIndex="visitors_rps" key="visitors_rps"/>
            <Column className='mini-head' title="Average TAT" dataIndex="min_rps" key="min_rps"/>
            {/* <Column className='mini-head' title="Total" dataIndex="total_rps" key="total_rps" /> */}
            <Column className='mini-head' title="RN Education CEU" dataIndex="CEU" key="CEU"/>
            <Column className='mini-head' title="Physician Education CME" dataIndex="CME" key="CME"/>
            <Column className='mini-head' title="RN Competencies" dataIndex="RN_comp" key="RN_comp" />
          </ColumnGroup>
        </ColumnGroup>
        
      </Table>
    </div>
  );
};

export default PHCReadinessTable;