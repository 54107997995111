import { isArabic } from "./helper";

export const TempObligationQuestionBody = ({
  field,
  props,
  translation = {},
}) => {
  const value =
    typeof field?.value === "object"
      ? field?.value?.label || "Object"
      : field.value;

  return (
    <div style={styles.wrapper} className="break-inside">
      <div
        style={styles.title_container}
        dir={isArabic(field.title) ? "rtl" : "ltr"}
        className="obli-question-body-title-container"
      >
        <p style={styles.title}>{translation[field.title] || field.title}</p>
      </div>
      <div
        style={styles.value_container}
        dir={isArabic(value) ? "rtl" : "ltr"}
        className="obli-question-body-value "
      >
        {value}
      </div>
    </div>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    justifyContent: "center",
    minHeight: 30,
    alignItems: "center",
    overflow: "hidden",
    // marginBottom: 10,
    flexDirection: "column",
    marginTop: -11,
    // borderRadius: 10,
  },
  title_container: {
    width: "100%",
    minHeight: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "red",
  },
  title: {
    margin: 0,
    fontSize: 16,
  },
  value_container: {
    width: "100%",
    padding: 10,
  },
};
