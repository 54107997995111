import React, { useEffect, useState } from "react";
import "./index.css";
import App from "./App";
import { getSchemaData } from "./Services/ApiServise";
import ArabicSa from "./assets/local/sa.json";
import ElmEodPdf from "./CustomPdfs/Elm/ElmEodPdf";
import StewardPdf from "./CustomPdfs/steward/StewardPdf";
import { Schema } from "@formily/json-schema";
import CompareReport from "./CompareReport";

const formBySlug = {
  "elm-eod": ElmEodPdf,
  "alulah-steward": StewardPdf,
  compare_submission: CompareReport,
};

export const Main = () => {
  const [routes, setRoutes] = useState(null);

  const [viewModal] = useState(() => new PDFViewModal("id"));
  const [viewModal1] = useState(() => new PDFViewModal("id_1"));

  const init = async () => {
    await viewModal.init();
    await viewModal1.init();
    setRoutes(true);
  };

  useEffect(() => {
    init();
  }, []);

  if (!routes) return null;

  // console.log("viewModal", viewModal);

  if (viewModal.form_slug) {
    const Component = formBySlug[viewModal.form_slug];
    if (Component) {
      return <Component viewModal={viewModal} viewModal1={viewModal1} />;
    }
  }

  return <App viewModal={viewModal} />;
};

class PDFViewModal {
  id = "";
  projectName = "";
  form_slug = "";

  // Schema data
  form = "";
  submissionInfo = "";
  formData = "";
  translation = "";
  steps = [];
  questions = [];
  answers = [];

  overallCompliance = 0;
  overallResult = {};
  maxAndMinComply = null;
  constructor(idParam) {
    const params = new URLSearchParams(new URL(window.location.href).search);
    this.id = params.get(idParam);
    this.projectName = params.get("project");
    this.form_slug = params.get("form_slug");
  }

  setForm(data) {
    this.form = data;
    this.parseSteps(new Schema(this.form.schema.schema));
  }

  setSubmissionInfo(data) {
    this.submissionInfo = data;
  }

  setSubmissionData(data) {
    this.formData = data;
  }

  traverseSchema = (schema, cb) => {
    let questions = [];
    if (schema.mapProperties) {
      schema.mapProperties((property, name) => {
        if (property.enum?.[0]?.step) {
          if (cb) {
            cb(name);
          }
          questions = [...questions, ...property.enum];
        }
        if (property.properties) {
          questions = [...questions, ...this.traverseSchema(property, cb)];
        }
      });
    }

    return questions;
  };

  stepsWithQuestions = (schema) => {
    let steps = [];
    if (schema.mapProperties) {
      schema.mapProperties((property, name) => {
        if (property?.["x-component"]?.indexOf?.("StepPane") > -1) {
          let attribute = "";
          let questions = this.traverseSchema(property, (key) => {
            attribute = key;
          });
          steps.push({
            name,
            props: property["x-component-props"],
            schema: property,
            attribute,
            questions: questions,
            data: this.formData[attribute],
            rating: this.extractAndGroupStepData(this.formData[attribute]),
          });
        }

        if (property.properties) {
          steps = [...steps, ...this.stepsWithQuestions(property)];
        }
      });
    }

    return steps;
  };

  parseSteps = (schema) => {
    this.steps = this.stepsWithQuestions(schema);
    this.questions = this.steps.map(({ questions }) => questions).flat();
    this.answers = this.steps
      .map(({ data }) => data)
      .flat()
      .filter((i) => i?.question);

    this.overallCompliance = (
      (this.complyAnswers.length /
        (this.complyAnswers.length + this.notComplyAnswers.length)) *
      100
    ).toFixed(2);
    this.overallCompliance = isNaN(this.overallCompliance)
      ? 0
      : this.overallCompliance;
    this.maxAndMinComply = this.mostAndLeastComply();

    this.overallResult = this.extractAndGroupStepData(this.answers);
  };

  mostAndLeastComply = () => {
    let data = this.steps
      .map((it) => {
        return {
          name: it.props.title,
          complyPercentage: it?.rating?.questionCounts?.complyPercentage,
        };
      })
      .filter((item) => item.complyPercentage > -1);

    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.complyPercentage]) {
        acc[item.complyPercentage] = [];
      }
      acc[item.complyPercentage].push(item.name);
      return acc;
    }, {});

    // Convert keys to numbers
    const percentages = Object.keys(groupedData).map((key) => parseFloat(key));

    // Find max and min values
    const maxPercentage = Math.max(...percentages);
    const minPercentage = Math.min(...percentages);

    // Retrieve associated values
    const maxValues = groupedData[maxPercentage.toString()];
    const minValues = groupedData[minPercentage.toString()];

    // Return the result as an object
    return {
      max: {
        percentage: maxPercentage,
        values: maxValues,
      },
      min: {
        percentage: minPercentage,
        values: minValues,
      },
    };
  };

  extractAndGroupStepData = (questions) => {
    if (!questions?.length) return null;
    const straightArray = [];
    const groupedData = {};
    const questionCounts = {
      Comply: 0,
      "Not Comply": 0,
      "N/A": 0,
    };

    const riskRatingsCount = {
      Low: 0,
      Medium: 0,
      // MediumHigh: 0,
      High: 0,
      Critical: 0,
    };

    const riskRatingsComply = {
      Low: 0,
      Medium: 0,
      // MediumHigh: 0,
      High: 0,
      Critical: 0,
    };

    const riskRatingsNotComply = {
      Low: 0,
      Medium: 0,
      // MediumHigh: 0,
      High: 0,
      Critical: 0,
    };

    questions.forEach((item) => {
      if(item.question === "Yes"){
        item.question = "Comply"
      }

      if(item.question === "No"){
        item.question = "Not Comply"
      }

      straightArray.push(item);
      const step = item.step;
      if (!groupedData[step]) {
        groupedData[step] = [];
      }
      groupedData[step].push(item);
      questionCounts[item.question]++;
      let isComply = item.question === "Comply";
      let isNotComply = item.question === "Not Comply";

      const riskRating = item.Likelihood * item.Impact;
      if (riskRating >= 0 && riskRating <= 2) {
        riskRatingsCount.Low++;
        if (isComply) {
          riskRatingsComply.Low++;
        }

        if (isNotComply) {
          riskRatingsNotComply.Low++;
        }
      } else if (riskRating >= 3 && riskRating <= 5) {
        riskRatingsCount.Medium++;
        if (isComply) {
          riskRatingsComply.Medium++;
        }
        if (isNotComply) {
          riskRatingsNotComply.Medium++;
        }
      } else if (riskRating >= 6 && riskRating <= 9) {
        riskRatingsCount.Medium++;
        if (isComply) {
          riskRatingsComply.Medium++;
        }
        if (isNotComply) {
          riskRatingsNotComply.Medium++;
        }
      } else if (riskRating >= 10 && riskRating <= 14) {
        riskRatingsCount.High++;
        if (isComply) {
          riskRatingsComply.High++;
        }
        if (isNotComply) {
          riskRatingsNotComply.High++;
        }
      } else {
        riskRatingsCount.Critical++;
        if (isComply) {
          riskRatingsComply.Critical++;
        }
        if (isNotComply) {
          riskRatingsNotComply.Critical++;
        }
      }
    });

    questionCounts.total =
      questionCounts.Comply +
      questionCounts["N/A"] +
      questionCounts["Not Comply"];

    questionCounts.complyPercentage =
      1 *
      (
        (questionCounts.Comply /
          (questionCounts.Comply + questionCounts["Not Comply"])) *
        100
      ).toFixed(2);

    return {
      straightArray,
      groupedData,
      questionCounts,
      riskRatingsCount,
      riskRatingsComply,
      riskRatingsNotComply,
    };
  };

  getCurrentStepData = (stepName) => {
    return this.steps.filter((step) => step.name == stepName);
  };

  setTranslation(t) {
    if (t) {
      this.translation = t.reduce(
        (r, c) => {
          r[c.english] = c.arabic;
          return r;
        },
        { ...ArabicSa }
      );
    }
  }

  async init() {
    if (this.id) {
      try {
        const { data } = await getSchemaData(this.id);

        this.setSubmissionData(data.formSubmission.data);
        this.setSubmissionInfo(data.formSubmission);
        this.setTranslation(data?.settings?.value);
        this.setForm(data.schema.data);
      } catch (error) {}
    }
  }

  get totalQuestions() {
    return this.questions.length;
  }

  get totalAnswers() {
    return this.answers.length;
  }

  get complyAnswers() {
    return this.answers.filter(({ question }) => question === "Comply");
  }

  get notComplyAnswers() {
    return this.answers.filter(({ question }) => question === "Not Comply");
  }

  get noOfVoilations() {
    return this.notComplyAnswers.length;
  }

  get noOfVoilationsCritical() {
    return this.notComplyAnswers.filter(
      (item) => item.Likelihood * item.Impact >= 15
    ).length;
  }

  get notApplicableAnswers() {
    return this.answers.filter(({ question }) => question === "N/A");
  }
}
