import Chart from "react-apexcharts";
import { getStandardClrObl } from "../../templates/helper";

export const StepChart = ({
  title,
  translation = {},
  rating,
  ratingComply,
  complyPercentage,
}) => {
  let { Low, Medium, High, Critical } = ratingComply;

  const totalQuestion = Object.entries(rating).reduce(
    (r, [key, value]) => r + value,
    0
  );

  const LowPercentage = (Low / totalQuestion) * 100;
  const MediumPercentage = (Medium / totalQuestion) * 100;
  const HighPercentage = (High / totalQuestion) * 100;
  const CriticalPercentage = (Critical / totalQuestion) * 100;

  const options = {
    colors: ["#38C793", "#F2AE40", "#DF1C41", "#AF1D38", "#800080"],
    plotOptions: {
      // dataLabels: {
      //   enabled: true,

      //   formatter: function (val) {
      //     return val + "asdsad";
      //   },
      // },
      pie: {
        customScale: 1,
        dataLabels: {
          enabled: false,
          offset: -10,
        },
      },
    },
    stroke: {
      show: false,
    },
    legend: {
      show: false, // Remove legend
    },
  };
  const series = [
    LowPercentage,
    MediumPercentage,
    HighPercentage,
    CriticalPercentage,
    100 -
      LowPercentage -
      MediumPercentage -
      HighPercentage -
      CriticalPercentage,
  ];

  return <Chart options={options} series={series} type="pie" width="250" />;
};

export const StepStats = ({ title, translation = {}, step }) => {
  if (!step?.data?.length) return null;
  let rating = step?.rating?.riskRatingsCount;
  let ratingComply = step?.rating?.riskRatingsComply;
  
  let complyPercentage = step?.rating?.questionCounts?.complyPercentage;
  complyPercentage = isNaN(complyPercentage) ? 0 : complyPercentage;
  return (
    <div className="stats-container break-inside" dir="ltr">
      <div className="stats-title-container">
        <p className="stats-title">{title}</p>
      </div>

      <div className="stats-body-container">
        <div className="compliance-percent-contane-stats">
          <div className="compliance-percent">
            <p className="compliance-percent-value">{complyPercentage}% </p>
            <p className="compliance-percent-heading">نسبة التزام القسم</p>
          </div>

          <div className="stats-pils-container">
            {Object.entries(rating).map(([key, value]) => {
              return (
                <p className="value_container-obl">
                  {ratingComply[key]} / {value} : {translation[key] || key}
                  <div
                    className="standard-obl-pill-dot"
                    style={{ ...getStandardClrObl(key) }}
                  />
                </p>
              );
            })}
          </div>
        </div>
      </div>
      <div className="chart-container">
        <StepChart
          rating={rating}
          ratingComply={ratingComply}
          complyPercentage={complyPercentage}
        />
      </div>
    </div>
  );
};
