import { connect, mapProps } from "@formily/react";

export const BaseItem = ({ children, ...props }) => {
  return children;
};

export const FormItem = connect(BaseItem);

FormItem.BaseItem = BaseItem;

export default FormItem;
