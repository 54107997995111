import React from 'react';
import "./style.css"

import { Space, Table, Tag, Row, Divider } from 'antd';

const { Column, ColumnGroup } = Table;

const ScreeningTable
 = ({data}) => {

  const siteName = data.screening_upload_picture
  const tableData = siteName?.map?.((siteNames, index) => ({
    key: index.toString(), 
    screening_type: data.screening_step?.map?.((item) => item.screening_type),
    location: data.screening_step?.map?.((item) => item.location), 
    date: data.screening_step?.map?.((item) => item.date), 
    screening_commentss: data.screening_commentss, 
    imageUrl: data.screening_upload_picture?.map?.((item) => item.response.imageUrl), 

  }));
  return (
    <div>
  <Table bordered dataSource={tableData} pagination={false}>
    <ColumnGroup className='main-head' title="Screening">
      <Column className='mini-head' title="Screening Type" dataIndex="screening_type" key="screening_type" 
      render={(screening_type) => (
        <>
          {screening_type?.map?.((tag) => {
            return (
              <Row style={{ marginTop: 10 }}>
                {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                {tag}
              </Row>
            );
          })}
        </>
      )}
      />
      <Column className='mini-head' title="Location" dataIndex="location" key="location"
      
      render={(location) => (
        <>
          {location?.map?.((tag) => {
            return (
              <Row style={{ marginTop: 10 }}>
                {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                {tag}
              </Row>
            );
          })}
        </>
      )}/>
      <Column className='mini-head' title="Date" dataIndex="date" key="date"
      
      render={(date) => (
        <>
          {date?.map?.((tag) => {
            return (
              <Row style={{ marginTop: 10 }}>
                {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                {tag}
              </Row>
            );
          })}
        </>
      )}/>
      <Column className='mini-head' title="Comment" dataIndex="screening_commentss" key="screening_commentss" />
      <Column className='mini-head' title="Pictures Upload" dataIndex="imageUrl" key="imageUrl"
          render={(imageUrl) => (
            <>
              {imageUrl?.map?.((tag) => {
                return (
                  <Row style={{ marginTop: 10 }}>
                    <a href={tag} target="_blank" >  
                    {tag}</a>
                  </Row>
                );
              })}
            </>
          )}/>
    </ColumnGroup>
  </Table>
        </div>
  );
};

export default ScreeningTable
;