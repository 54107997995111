import React, { Fragment } from "react";
import { Template3 } from "./templates/Template3";
import { StepTemplate } from "./obligation/stepTemplate/StepTemplate";
import { TemplateObligation } from "./templates/TemplateObligation";
import { TempObligationQuestionBody } from "./templates/tempObligationQuestionBody";

function ObligScoring({ formName, topReading, translation, viewModal }) {
  const { questionCounts, notComplySteps } = topReading;

  const questionTypes = [
    { type: "Comply", color: "green", label: "ملتزم" },
    {
      type: "Not Comply",
      color: "red",
      label: "غير ملتزم",
    },
    { type: "N/A", color: "orange", label: "لا ينطبق" },
  ];

  const renderQuestionTypes = questionTypes.map(
    ({ type, color, label, Logo }) => (
      <div className={`custom-table custom-table-${color}`} key={type}>
        <div
          className={`custom-table-header custom-table-${color}-header`}
          style={{ fontSize: 24, fontWeight: 700 }}
        >
          {questionCounts[type]}
        </div>
        <div className="custom-table-body">
          <div
            className={`custom-table-cell custom-table-${color}-cell`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {Logo && <img alt="" width="7%" src={Logo} />}
            &nbsp;
            {translation[label] || label}
          </div>
        </div>
      </div>
    )
  );

  if (questionCounts.total < 1) {
    return null;
  }

  let styles = `
  .page-break-always {
    page-break-before: always !important;
  }
  
  @media print {
    .page-break-always {
        display: block;
        page-break-before: always;
    }
  }
  `;

  return (
    <div className="pdf-header">
      <style>{styles}</style>
      <div className="inventoryRowNew" style={{ width: "100%" }}>
        <div className=" score-contanier">
          <div className="stats-title-container">
            <p className="stats-title">تصنيف</p>
          </div>
          <div className="grid-container break-inside widget-data">
            <div className="custom-table custom-table-blue">
              <div
                className="custom-table-header custom-table-blue-header"
                dir="ltr"
              >
                {questionCounts.total} / {questionCounts.totalQuestions}
              </div>
              <div className="custom-table-body custom-table-blue-body">
                <div className="custom-table-cell custom-table-blue-cell">
                  {translation["Standards"] || "Standards"}
                </div>
              </div>
            </div>
            {renderQuestionTypes}
          </div>
        </div>

        {viewModal.maxAndMinComply.max.percentage > -1 && (
          <>
            <div className="compliance-percent  break-inside">
              <p
                className="compliance-percent-heading"
                style={{ fontSize: 14 }}
              >
                <span style={{ fontSize: 16, fontWeight: "700" }}>
                  {" "}
                  القسم الأكثر التزام{" "}
                </span>
                ({` `}
                {viewModal.maxAndMinComply?.max?.values?.join?.(" ، ")} )
              </p>
              <p className="compliance-percent-value" style={{ fontSize: 16 }}>
                {viewModal.maxAndMinComply.max.percentage}%
              </p>
            </div>

            <div className="compliance-percent  break-inside">
              <p
                className="compliance-percent-heading"
                style={{ fontSize: 14 }}
              >
                <span style={{ fontSize: 16, fontWeight: "700" }}>
                  {" "}
                  القسم الأقل التزام
                </span>
                {` `}( {` `}
                {viewModal?.maxAndMinComply?.min?.values?.join?.(" ، ")} )
              </p>
              <p className="compliance-percent-value" style={{ fontSize: 16 }}>
                {viewModal?.maxAndMinComply?.min?.percentage}%
              </p>
            </div>
          </>
        )}

        {!!Object.entries(notComplySteps).length && (
          <>
            <StepTemplate title={"الملاحظات"} />
            {Object.entries(notComplySteps).map(([title, value], i) => {
              return (
                <span key={i} className="wrapper">
                  {value.map((item, index) => {
                    return (
                      <Fragment key={index}>
                        <TemplateObligation
                          translation={translation}
                          field={{
                            ...item,
                            title: item.title,
                            value: item.question,
                          }}
                        />
                        <TempObligationQuestionBody
                          translation={translation}
                          field={{
                            title: "Reason",
                            value: item.reason,
                          }}
                        />
                        <Template3
                          translation={translation}
                          comp={"Upload"}
                          field={{
                            title: "Attachments",
                            value: item.files,
                          }}
                        />
                      </Fragment>
                    );
                  })}
                </span>
              );
            })}
          </>
        )}
      </div>
      <div class="page-break-always"></div>
    </div>
  );
}

export default ObligScoring;
