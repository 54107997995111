import React from 'react';
import "./style.css"

import { Space, Table, Tag, Row, Divider } from 'antd';
const { Column, ColumnGroup } = Table;

const RiskAndMigrateTable = ({ data }) => {
  const siteName = data.risk_and_mitigation_w3
  const tableData = siteName?.map?.((siteNames, index) => ({
    key: index.toString(),
    risk: data.risk_and_mitigation_w3?.map?.((item) => item.risk),
    mitigration: data.risk_and_mitigation_w3?.map?.((item) => item.mitigration),
    likehood: data.risk_and_mitigation_w3?.map?.((item) => item.likehood),
    accountability: data.risk_and_mitigation_w3?.map?.((item) => item.accountability),


  }));
  return (
    <div>
      <Table bordered dataSource={tableData} pagination={false} >
        <ColumnGroup className='main-head' title="Risks and Mitigations">
          <Column className='mini-head' title="Risk" dataIndex="risk" key="risk"
          render={(risk) => (
            <>
              {risk?.map?.((tag) => {
                return (
                  <Row className="value-style" style={{ marginTop: 10, }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}
          />
          <Column className='mini-head' title="Mitigation" dataIndex="mitigration" key="mitigration"
          
          render={(mitigration) => (
            <>
              {mitigration?.map?.((tag) => {
                return (
                  <Row className="value-style" style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}/>
          <Column className='mini-head' title="Likelihood" dataIndex="likehood" key="likehood"
          render={(likehood) => (
            <>
              {likehood?.map?.((tag) => {
                return (
                  <Row className="value-style" style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}
          />
          <Column className='mini-head' title="Accountability" dataIndex="accountability" key="accountability"
          render={(accountability) => (
            <>
              {accountability?.map?.((tag) => {
                return (
                  <Row className="value-style" style={{ marginTop: 10 }}>
                    {/* <Divider style={{ margin: 0, marginTop: 5, marginBottom: 5 }} /> */}
                    {tag}
                  </Row>
                );
              })}
            </>
          )}
          />
        </ColumnGroup>
      </Table>
    </div>
  );
};

export default RiskAndMigrateTable;