import React, { useEffect, useMemo, useState } from "react";
import {
  Form,
  // FormItem,
  Checkbox,
  Cascader,
  Editable,
  Input,
  NumberPicker,
  Switch,
  Password,
  PreviewText,
  Radio,
  Reset,
  Select,
  Space,
  Submit,
  Transfer,
  TreeSelect,
  Upload,
  // FormGrid,
  FormLayout,
  FormTab,
  FormCollapse,
  ArrayTable,
  // FormStep
} from "@formily/antd";
import { FormPath, isObj } from "@formily/shared";
import HeaderObligation from "./components/obligation/header/HeaderObligation";

import TimePicker from "./components/TimePicker";
import DatePicker from "./components/DatePicker";
import DatePickerHijri from "./components/DatePickerHijri";
import ArrayCards from "./components/ArrayCards";
import FormStep from "./components/form-step";
import Text from "./components/Text";
import Signature from "./components/Signature";
import { Schema } from "@formily/json-schema";

import "antd/dist/antd.css";
import { StyleProvider } from "@ant-design/cssinjs";
import moment from "moment";
import lodash, { property } from "lodash";

import "@formily/antd/dist/antd.css";

import { createForm } from "@formily/core";
import { createSchemaField } from "@formily/react";
import * as FormilyCore from "@formily/core";
import * as FormilyReact from "@formily/react";
import * as FormilyAntd from "@formily/antd";
import * as Antd from "antd";
import * as FormilyReactive from "@formily/reactive";

import { Card, Slider, Rate, ConfigProvider } from "antd";
import { getComponent } from "./components/templates/TemplateUtils";
import { getSchemaData } from "./Services/ApiServise";
import FormItem from "./components/form-item";
import ArabicSa from "./assets/local/sa.json";

import HeaderPdf from "./components/HeaderPdf";
import Scoring from "./components/Scoring";
import { StepStats } from "./components/obligation/stepStats/StepStats";
import ObligScoring from "./components/ObligScoring";
// const Radio = (props) => {
//   return <h4>Hello</h4>;
// };
// Radio.Group = (props) => {
//   return <h4>{props.value}</h4>;
// };
const SchemaField = createSchemaField({
  components: {
    // Space: () => null,
    // FormGrid: () => null,
    // FormLayout,
    // FormTab: () => null,
    // FormCollapse: () => null,
    ArrayTable,
    ArrayCards,
    FormItem: (props) => props.children,
    // DatePicker: () => null,
    // Checkbox: () => null,
    // Cascader: () => null,
    // // Editable: () => null,
    // // Input: () => null,
    // // Text: () => null,
    // NumberPicker: () => null,
    // Switch: () => null,
    // Password: () => null,
    // // PreviewText: () => null,
    // Radio: () => null,
    // Reset: () => null,
    // Select: () => null,
    // Submit: () => null,
    // TimePicker: () => null,
    // Transfer: () => null,
    // TreeSelect: () => null,
    // Upload: () => null,
    // Card: ({ children }) => children,
    // Slider: () => null,
    // Rate: () => null,
    // FormStep,
    // Signature: () => null,
    // DatePickerHijri: () => null,
    // Divider: () => null,
  },
});

function extractAndGroupStepData(obj) {
  const straightArray = [];
  const groupedData = {};
  const questionCounts = {
    Comply: 0,
    "Not Comply": 0,
    "N/A": 0,
  };

  const riskRatingsCount = {
    Low: 0,
    Medium: 0,
    MediumHigh: 0,
    High: 0,
    Critical: 0,
  };

  // Loop over each key in the object
  for (const key in obj) {
    // Check if the value corresponding to the key is an array
    if (Array.isArray(obj[key])) {
      // Check if the array contains objects with a 'step' property
      const hasStepData = obj[key].every((item) => item.hasOwnProperty("step"));
      if (hasStepData) {
        // Extract step data and update counts
        obj[key].forEach((item) => {
          straightArray.push(item);
          if (item.question === "Yes") {
            item.question = "Comply";
          }
          if (item.question === "No") {
            item.question = "Not Comply";
          }
          const step = item.step;
          if (!groupedData[step]) {
            groupedData[step] = [];
          }
          groupedData[step].push(item);
          questionCounts[item.question]++;

          const riskRating = item.Likelihood * item.Impact || 0;
          if (riskRating >= 0 && riskRating <= 2) {
            riskRatingsCount.Low++;
          } else if (riskRating >= 3 && riskRating <= 5) {
            riskRatingsCount.Medium++;
          } else if (riskRating >= 6 && riskRating <= 9) {
            riskRatingsCount.MediumHigh++;
          } else if (riskRating >= 10 && riskRating <= 14) {
            riskRatingsCount.High++;
          } else {
            riskRatingsCount.Critical++;
          }
        });
      }
    }
  }

  questionCounts.total =
    questionCounts.Comply +
    questionCounts["N/A"] +
    questionCounts["Not Comply"];

  return { straightArray, groupedData, questionCounts, riskRatingsCount };
}

// Function to filter and group steps containing only the question "Not Comply"
function filterAndGroupNotComplySteps(groupedData) {
  const notComplySteps = {};

  for (const step in groupedData) {
    const notComplyData = groupedData[step].filter(
      (item) => item.question === "Not Comply"
    );
    if (notComplyData.length > 0) {
      notComplySteps[step] = notComplyData;
    }
  }

  return notComplySteps;
}

const arabicProjects = ["Aman"];

const FormRender = ({ viewModal }) => {
  const formStep = useMemo(() => FormStep.createFormStep(), []);
  const {
    form,
    formData,
    translation,
    submissionInfo,
    id,
    projectName,
    questions,
    getCurrentStepData,
  } = viewModal;

  const ScoringResult = useMemo(() => {
    if (formData && projectName === "Aman") {
      let data = JSON.parse(JSON.stringify(formData));
      const { straightArray, groupedData, questionCounts, riskRatingsCount } =
        extractAndGroupStepData(data);

      questionCounts.totalQuestions = questions.length;
      const notComplySteps = filterAndGroupNotComplySteps(groupedData);

      const topReading = {
        // straightArray,
        // groupedData,
        questionCounts,
        notComplySteps,
        riskRatingsCount,
      };

      return (
        <ObligScoring
          topReading={topReading}
          translation={translation}
          viewModal={viewModal}
        />
      );
    }
    return null;
  }, [formData, questions, projectName, translation]);

  const [allData, setFormData] = useState();

  const renderForm = useMemo(() => {
    return createForm({
      values: formData,
      effects() {
        FormilyCore.onFormMount((form) => {
          setFormData(form.getFormGraph());
        });
      },
    });
  }, [formData]);

  const formUtils = useMemo(() => {
    return {
      formId: form?._id,
      submissionId: id,
      isShow: true,
      isEdit: !!id,
    };
  }, [form, id]);

  const schema = useMemo(() => {
    if (form?.schema?.schema) {
      const _schema = JSON.parse(JSON.stringify(form?.schema?.schema));
      return _schema;
    }
  }, [form?.schema]);

  const GlobalUtility = useMemo(() => {
    if (form?.schema?.form) {
      const classString =
        form?.schema?.form?.global_utils ||
        `
      class GlobalUtilsClass {
        constructor() {
        }
      };
  `;

      const createClassFromStr = new Function(
        classString + " return GlobalUtilsClass;"
      );
      const DynamicClass = createClassFromStr();
      return new DynamicClass({
        lodash,
        moment,
        intl: {},
        toast: {},
        FormilyCore,
        FormilyReact,
        Antd,
        FormilyAntd,
        SchemaField,
        SC: {},
        FormilyReactive,
        user: {},
        renderForm,
        response: submissionInfo,
      });
    }
  }, [form?.schema?.form]);

  if (!schema) return <h4>Loading...</h4>;

  let previousStep = {
    current: 0,
  };

  let yesCount = 0;
  let noCount = 0;
  let notTargetedCount = 0;

  let data = Object.entries(renderForm.getFormGraph() || {})
    .map(([key, field], i) => {
      if (!field.visible) return null;

      if (field.value === "Yes") {
        yesCount++;
      } else if (field.value === "No") {
        noCount++;
      } else if (field.value === "Not Targeted") {
        notTargetedCount++;
      }

      return (
        <span className="wrapper" key={i + key}>
          <span style={{ color: "transparent", height: 0, width: 0 }}></span>
          {getComponent(
            field,
            renderForm,
            translation,
            ScoringResult,
            previousStep,
            allData,
            submissionInfo,
            getCurrentStepData
          )}
        </span>
      );
    })
    .filter((i) => i);

  return (
    <div
      dir={arabicProjects.includes(projectName) ? "rtl" : "none"}
      style={{ padding: 10 }}
    >
      <div style={{ display: "none" }}>
        <Form form={renderForm}>
          <SchemaField
            schema={schema}
            scope={{
              formStep,
              moment,
              lodash,
              formUtils,
              GlobalUtils: GlobalUtility,
              FormilyCore,
              FormilyReact,
              Antd,
              FormilyAntd,
              SchemaField,
              FormilyReactive,
              renderForm,
            }}
          />
        </Form>
      </div>
      <div>
        <HeaderObligation
          formName={form.name}
          submissionInfo={submissionInfo}
          translation={translation}
        />
        {projectName === "Aman" && viewModal?.totalQuestions > 0 && (
          <>
            {parseInt(viewModal.overallCompliance) > -1 && (
              <div className="compliance">
                <div className="compliance-percent" style={{ marginTop: 14 }}>
                  <p className="compliance-percent-heading">
                    نسبة التزام المنشأة
                  </p>
                  <p className="compliance-percent-value">
                    {viewModal.overallCompliance}%
                  </p>
                </div>
                <div style={{ display: "flex", gap: 10, marginTop: 10 }}>
                  <div className="compliance-percent">
                    <p
                      className="compliance-percent-heading"
                      style={{ fontSize: 16, color: "#F17B2C" }}
                    >
                      عدد الملاحظات
                    </p>
                    <p
                      className="compliance-percent-value"
                      style={{ fontSize: 16, color: "#F17B2C" }}
                    >
                      {viewModal.noOfVoilations}
                    </p>
                  </div>
                  <div className="compliance-percent break-inside">
                    <p
                      className="compliance-percent-heading"
                      style={{ fontSize: 16, color: "#DF1C41" }}
                    >
                      عدد الملاحظات الجسيمة
                    </p>
                    <p
                      className="compliance-percent-value"
                      style={{ fontSize: 16, color: "#DF1C41" }}
                    >
                      {viewModal.noOfVoilationsCritical}
                    </p>
                  </div>
                </div>
              </div>
            )}

            <div className="compliance-percent  break-inside">
              <p
                className="compliance-percent-heading"
                style={{ fontSize: 14 }}
              >
                <span style={{ fontSize: 16 }}>عدد الأقسام الغير مستهدفة</span>
              </p>
              <p className="compliance-percent-value" style={{ fontSize: 16 }}>
                {notTargetedCount}
              </p>
            </div>
            <div className="compliance-percent  break-inside">
              <p
                className="compliance-percent-heading"
                style={{ fontSize: 14 }}
              >
                <span style={{ fontSize: 16 }}>عدد الأقسام الغير متاحة</span>
              </p>
              <p className="compliance-percent-value" style={{ fontSize: 16 }}>
                {noCount}
              </p>
            </div>
          </>
        )}
      </div>

      <div className="parent">
        {ScoringResult}
        {data}

        {submissionInfo.vsight_links?.length ? (
          <div style={{ width: "100%" }}>
            <div className="break-inside score-contanier">
              <div className="stats-title-container">
                <p className="stats-title">Virtual session files</p>
              </div>
              <div
                className="widget-data"
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                }}
              >
                {submissionInfo.vsight_links.map((file, i) => (
                  <div
                    className="break-inside"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img src={file} style={{ width: 200, height: 200 }} />
                    <a href={file} target="_blank">
                      {translation["Attachment"] || "Attachment"} # {i + 1}
                    </a>
                    &nbsp;&nbsp;
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className="critical-content"></div>
    </div>
  );
};

export default FormRender;
