import React from "react";
import Logo1 from "../../../assets/aman_logo.png";
import "./style.css";
const AllLogos = {
  Aman: Logo1,
};

function CorrectiveHeader({ percentage }) {
  return (
    <>
      <div className="head-bottom-info">
        <div>
          <p className="head-bottom-info-text">
            نسبة التصحيح: {percentage > 0 && "%"} {percentage}{" "}
          </p>
        </div>
      </div>
    </>
  );
}

export default CorrectiveHeader;
